import React, { HTMLAttributes } from "react";
import cx from "classnames";

import * as classNames from "./Arrow.module.css";

export enum ArrowDirection {
  LEFT,
  RIGHT,
}

export type ArrowProps = HTMLAttributes<SVGElement> & {
  direction: ArrowDirection;
};

const Arrow: React.FC<ArrowProps> = ({ className, direction, ...props }) => {
  const path =
    direction === ArrowDirection.LEFT
      ? "M15.245,28.990 L1.500,15.245 L15.245,1.500 "
      : "M1.500,1.500 L15.244,15.245 L1.500,28.990 ";
  return (
    <svg
      className={cx(classNames.arrow, className)}
      {...props}
      viewBox="0 0 18 31"
    >
      <path className={classNames.path} d={path} />
    </svg>
  );
};

export default Arrow;
