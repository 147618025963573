import React, { FC, HTMLAttributes } from "react";
import cx from "classnames";
import * as classNames from "./AdjacentPostButton.module.css";
import { Link } from "gatsby";
import Arrow, { ArrowDirection } from "./Arrow";
import { Post, UNTITLED } from "../graphql";

export enum Direction {
  PREVIOUS = "prev",
  NEXT = "next",
}

export type AdjacentPostButtonProps = HTMLAttributes<HTMLAnchorElement> & {
  direction: Direction;
  post?: Post;
};

/**
 * Generates a paginator for the blog archive.
 *
 * The `pageSpread` determines how wide the paginator is; it's the
 * number of links on either side of the center link, so you'll end
 * up with a list of the following sizes:
 *
 * | Spread | Count |
 * | ------ | ----- |
 * | 1      | 3     |
 * | 2      | 5     |
 * | 3      | 7     |
 *
 * Et cetera.
 */
const AdjacentPostButton: FC<AdjacentPostButtonProps> = ({
  direction,
  post,
  className,
  ...props
}) => {
  const permalink = post?.frontmatter.permalink;
  if (!permalink) {
    return null;
  }
  const title = post?.frontmatter.title ?? UNTITLED;
  const arrowDirection =
    direction === Direction.PREVIOUS
      ? ArrowDirection.LEFT
      : ArrowDirection.RIGHT;
  const prefix = direction === Direction.PREVIOUS ? "Previous:" : "Next:";
  return (
    <Link
      to={permalink}
      className={cx(classNames.root, classNames[direction], className)}
      {...props}
    >
      <span className={classNames.text}>
        {prefix} {title}
      </span>
      <Arrow
        className={classNames.arrow}
        direction={arrowDirection}
        role="presentation"
      />
    </Link>
  );
};
export default AdjacentPostButton;
