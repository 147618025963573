import React from "react";
import { PageProps } from "gatsby";
import Layout from "../components/Layout";
import Head, { HeadProps } from "../components/Head";
import Header from "../components/Header";
import MarkdownContent from "../components/MarkdownContent";
import Monogram from "../components/Monogram";
import AdjacentPostButton, {
  Direction,
} from "../components/AdjacentPostButton";
import { Author, UNTITLED } from "../graphql";
import { humanReadableDate, isoDatetime } from "../util/dates";
import * as classNames from "./PostPage.module.css";
import { PostContext } from "../util/page-types";
import PostMedia from "./PostMedia";

export type PostProps = Readonly<PageProps<null, PostContext>>;

const PostPage: React.FC<PostProps> = ({ pageContext }) => {
  const {
    frontmatter: { date, title = UNTITLED, author = Author.Ryan, image, media },
    excerpt = "",
    body,
  } = pageContext.post;
  const meta: HeadProps["meta"] = [];

  let subtitle;
  if (date) {
    meta.push({
      property: "article:published_time",
      content: isoDatetime(date),
    });
    subtitle = `Published ${humanReadableDate(date)}`;
  }
  return (
    <Layout>
      <Head
        title={title}
        description={excerpt}
        meta={meta}
        image={image}
        type="article"
      />
      <Header title={title} subtitle={subtitle} />
      {author !== Author.Kara && (
        <MarkdownContent
          className={classNames.disclaimer}
          html={pageContext.disclaimer.body}
        />
      )}
      {media && <PostMedia media={media} />}
      <MarkdownContent dropcap={true} html={body} />
      <Monogram footer={true} />
      <AdjacentPostButton
        direction={Direction.PREVIOUS}
        post={pageContext.previous}
      />
      <AdjacentPostButton direction={Direction.NEXT} post={pageContext.next} />
    </Layout>
  );
};

export default PostPage;
